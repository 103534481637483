import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Grid } from "@chakra-ui/react"

import AuthorCard from "../components/AuthorCard"
import Layout from "../components/Layout"
import Section from "../components/Section"
import SEO from "../components/SEO"

const filterPostsByAutor = (articles, authorUid) => {
  const authorArticles = articles.filter((a) => a.nodes[0].data.author.uid === authorUid)
  return authorArticles[0] ? authorArticles[0].nodes : []
}
const AuthorsPage = ({ data }) => {
  const articles = data.allPrismicArticle.group
  return (
    <Layout heroCopy={data.prismicAuthors.data.hero_copy} heroImage={data.prismicAuthors.data.hero_image}>
      <SEO title="Our Authors" />
      <Section>
        <Grid gridTemplateColumns={["1fr", "repeat(3, 1fr)"]} gridGap={[8, 12]}>
          {data.allPrismicAuthor.nodes.map((a) => {
            return <AuthorCard key={a.uid} author={a} articles={filterPostsByAutor(articles, a.uid)} />
          })}
        </Grid>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query AuthorsQuery {
    allPrismicAuthor(sort: { fields: data___name, order: ASC }) {
      nodes {
        uid
        type
        data {
          avatar {
            gatsbyImageData(width: 200, height: 200)
          }
          name
        }
      }
    }
    prismicAuthors {
      data {
        hero_copy
        hero_image {
          gatsbyImageData(width: 2560, height: 700)
        }
      }
    }
    allPrismicArticle(sort: { order: DESC, fields: [first_publication_date] }) {
      group(field: data___author___uid, limit: 3) {
        nodes {
          uid
          data {
            title
            author {
              uid
            }
            articletype
            country
          }
          type
        }
      }
    }
  }
`

AuthorsPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
}

export default AuthorsPage
