import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { linkResolver } from "../gatsby/linkResolver"
import Link from "./Link"

const AuthorCard = ({ author, articles }) => {
  return (
    <div sx={{ backgroundColor: "secondaryBg", pb: 3 }}>
      <Link to={linkResolver(author)}>
        <h3
          sx={{
            fontWeight: "bold",
            pl: 4,
            pb: 2,
            "&:hover": {
              color: "primary",
            },
          }}
        >
          {author.data.name}
        </h3>
      </Link>
      <Link to={linkResolver(author)}>
        <GatsbyImage
          image={getImage(author.data.avatar)}
          sx={{
            maxHeight: "200px",
            mt: 3,
            mb: 5,
            "&:hover picture": {
              opacity: ".85",
              transition: "opacity .1s ease-out",
            },
          }}
        />
      </Link>
      {articles &&
        articles.map((a) => (
          <Link to={linkResolver(a)} key={a.uid}>
            <h5
              sx={{
                px: 4,
                "&:hover": {
                  color: "primary",
                },
                mb: 3,
              }}
            >
              {a.data.title}
            </h5>
          </Link>
        ))}
      {!articles && author.data.aboutme && <p sx={{ px: 4 }}>{author.data.aboutme.text}</p>}
    </div>
  )
}

AuthorCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  author: PropTypes.object.isRequired,
  articles: PropTypes.arrayOf(PropTypes.object),
}

AuthorCard.defaultProps = {
  articles: null,
}

export default AuthorCard
